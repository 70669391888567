import { DnaSpinner } from "tennisinsiderwebapp";
import pink from "@material-ui/core/colors/pink";
import cyan from "@material-ui/core/colors/cyan";
import "./App.css";
import ReactGA from "react-ga";
import { useEffect } from "react";

if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <DnaSpinner
          titleId="DnaSpinner"
          title="DnaSpinner"
          backgroundColor="#282c34"
          colorsWave31={pink[200]}
          colorsWave32={pink[400]}
          colorsWave41={cyan[200]}
          colorsWave42={cyan[400]}
        />
        <p>Coming soon...</p>
      </header>
    </div>
  );
}

export default App;
